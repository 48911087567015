import React from "react";
import FooterNavigation from "../components/footer-navigation";
import ZebrawebLogo from "../images/logo_zebraweb.svg";

function Footer({ siteTitle }) {
  return (
    <footer>
      <FooterNavigation />

      <div className="py-4">
        <a href="/https://zebraweb.se" target="_blank"><img className="block mx-auto w-24" src={ZebrawebLogo} title="En webbplats av Zebraweb" alt="Logotyp Zebraweb" /></a>
      </div>
    </footer>
  )
}

export default Footer;
