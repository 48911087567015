import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";

const Layout = ({children}) => {
  return (
    <React.Fragment>
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
