import { StaticQuery, graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const CtaTjanster = () => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: {name: {eq: "Startsida"}}) {
          frontmatter {
            cta_tjanster {
              heading
              blocks {
                heading
                body
                url
                icon {
                  id
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className="bg-grey-lightest relative py-8 md:pb-0">
      
        <h2 className="text-center mb-6 lg:mb-12">{data.markdownRemark.frontmatter.cta_tjanster.heading}</h2>

        <div className="container mx-auto  overflow-y-visible">
          <div className="flex flex-wrap relative mb-8 md:mb-0 md:p-4 xl:px-12 xl:flex-no-wrap">
            {data.markdownRemark.frontmatter.cta_tjanster.blocks.map((block, i) => (
              <section className="px-4 py-2 relative w-full sm:w-1/2 lg:w-1/4 md:px-4 md:pb-8" key={i}>
                <Link
                  to={block.url}
                  className="no-underline text-grey-darkest"
                >
                  <div className="flex md:block p-4 bg-white shadow-lg xl:px-8 xl:pb-8 md:pt-16 hover:shadow-xl" style={{ transition: 'all .2s'}}>
                    <StickerWrapper className="items-center justify-center flex md:absolute">
                      <Sticker className="shadow-md bg-orange rounded-full items-center justify-center flex">
                        <img className="h-6 md:h-8" src={block.icon.publicURL} alt=""/>
                      </Sticker>
                    </StickerWrapper>

                    <div className="w-auto pl-8 flex items-center md:text-center md:block md:pl-0 md:w-full">
                      <h3 className="font-bold mt-0 mb-0 text-lg md:mb-4" style={{ hyphens: 'auto' }}>{block.heading}</h3>
                      <p className="hidden text-sm md:block md:mb-2" style={{ hyphens: 'auto' }}>{block.body}</p>
                      <span className="link hidden md:block text-orange">Läs mer</span>
                    </div>
                  </div>
                </Link>
              </section>
            ))}
          </div>
        </div>
      </section>
    )}
  />
)

const StickerWrapper = styled.div`
  @media screen and (min-width: 768px) {
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    width: 92px;
    height: 92px;
  }
`

const Sticker = styled.div`
  width: 40px;
  height: 40px;

  @media screen and (min-width: 768px) {
    width: 70px;
    height: 70px;
  }
  @media screen and (min-width: 1280px) {
    width: 92px;
    height: 92px;
  }
`

export default CtaTjanster;