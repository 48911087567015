import { Link, StaticQuery, graphql } from "gatsby";
import React from "react";

export default() => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "article-front-featured.png" }) {
          childImageSharp {
            fluid(maxWidth: 397) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <section className="w-full bg-blue">
      <div className="mx-auto py-8 px-4 flex flex-wrap justify-between max-w-sm font-mono">
        <Link
            to="/tjanster"
            className="block px-2 no-underline text-white text-xs uppercase tracking-wide md:inline-block"
          >
            Tjänster
        </Link>
        <Link
            to="/vara-projekt"
            className="block px-2 no-underline text-white text-xs uppercase tracking-wide md:inline-block"
          >
            Projekt
        </Link>
        <Link
            to="/kontakt"
            className="block px-2 no-underline text-white text-xs uppercase tracking-wide md:inline-block"
          >
            Kontakt
        </Link>
        <Link
            to="/om-oss"
            className="block px-2 no-underline text-white text-xs uppercase tracking-wide md:inline-block"
          >
            Om oss
        </Link>
      </div>
    </section>
    )}
  />
)