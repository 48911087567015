import { Link } from "gatsby";
import React, { useState } from "react";
import logoBrosvets from "../images/logo-brosvets.svg";
import logoFacebook from "../images/logo-facebook.svg";

function Menu({ menuLinks }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <nav className="gradient absolute z-10 w-full font-mono">
      <div className="flex flex-wrap items-start justify-between container mx-auto md:p-8 xl:px-16">
        <Link to="/" className="logo">
          <img
            src={logoBrosvets}
            className="block m-4 w-full md:m-0"
            alt="Logo Bro Svets"
          />
        </Link>

        <button
          className="block md:hidden border border-white flex items-center m-4 md:m-0 px-3 py-2 rounded text-white relative z-10"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <div className={`${isExpanded ? `block` : `hidden`} bg-blue w-full p-12 text-center absolute md:p-0 md:static md:block md:flex md:items-start md:w-auto md:bg-transparent`}>
          <nav className="text-sm">
              {menuLinks.map(link => (
                <div key={link.name} className={(link.subMenu != null ? 'has-children ' : '') + "relative mt-4 md:mr-12 md:mt-0 md:inline-block"}>
                <Link
                  key={link.name}
                  to={link.link}
                  className="block py-2 uppercase tracking-wider text-lg no-underline text-white md:text-xs md:py-0 hover:text-orange"
                  style={{transition: 'color .15s'}}
                  activeClassName="active-link"
                >
                  {link.name}
                </Link>
                {subMenu(link.subMenu)}
                </div>
              ))}

            <a href="https://www.facebook.com/brosvets/" target="_blank" rel="noopener noreferrer" className="logo-facebook block md:inline-block mt-6 mb-4 md:mt-0 md:mb-0 no-underline text-white relative"><img src={logoFacebook} alt="Logo Bro Svets" /></a>
          </nav>
        </div>
      </div>
    </nav>
  )
}

function subMenu(subMenu) {
  if(subMenu) {
    return (
      <div className="sub-menu text-center">
      {subMenu.map(subLink => (
        <Link
          key={subLink.name}
          to={subLink.link}
          className=""
          style={{width: 'max-content', transition: 'color .15s'}}
          activeClassName="active-link"
        >
          {subLink.name}
        </Link>
    ))}  
      </div>
      )
  } else {
    return;
  }

}

export default Menu;
