import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import bannerBg from "../images/banner-bg-2545x420.jpg";

const CtaBanner = () => (
  <section className="bg-grey-lightest text-center">
    <Banner className="p-8 xl:py-16">
      <h2 className="text-white h1  mb-8 max-w-lg mx-auto">Vi hjälper er med montagearbeten på plats!</h2>
      <Link
        to="/montagearbeten"
        className="link button"
      >
        Läs mer
      </Link>
    </Banner>
  </section>
)

const Banner = styled.div`
background-image: url(${bannerBg});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
`

export default CtaBanner;