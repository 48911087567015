import { StaticQuery, graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Menu from "./menu-header";

const Header = (props) => (
  <StaticQuery
    render={data => (
      <header className="relative">
        <Menu menuLinks={data.site.siteMetadata.menuLinks}/>
        
        <Hero className="bg-blue" bgXsmall={data.hero_xs.publicURL} bgSmall={data.hero_sm.publicURL} bgLarge={data.hero_lg.publicURL} bgXlarge={data.hero_xl.publicURL}>
          <section className="container mx-auto px-12 text-center md:p-8 xl:p-16">
            <h1 className="text-white mb-4 mx-auto sm:w-4/5 md:w-3/5 lg:w-4/5 text-3xl xs:text-4xl xl:text-5xl">{data.markdownRemark.frontmatter.text_header.heading}</h1>
            <p className="text-white xl:text-xl mb-6 mx-auto font-bold xl:w-2/5">{data.markdownRemark.frontmatter.text_header.body}</p>
            <Link
                to={data.markdownRemark.frontmatter.text_header.btn_url}
                className="link button"
              >
              {data.markdownRemark.frontmatter.text_header.btn_label}
            </Link>
          </section>
        </Hero>
      </header>
    )}

    query={graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
              subMenu {
                name
                link
              }
            }
          }
        }

        hero_xs: file(relativePath: { eq: "hero-bg-576x450-u.jpg" }) {
          publicURL
        }
        hero_sm: file(relativePath: { eq: "hero-bg-1536x1200-u.jpg" }) {
          publicURL
        }
        hero_lg: file(relativePath: { eq: "hero-bg-1440x548-u.jpg" }) {
          publicURL
        }
        hero_xl: file(relativePath: { eq: "hero-bg-2545x969-u.jpg" }) {
          publicURL
        }
        
        markdownRemark(frontmatter: {name: {eq: "Startsida"}}) {
          frontmatter {
            text_header {
              heading
              body
              btn_label
              btn_url
            }
          }
        }
      }
    `}
  />
)

const Hero = styled.div`
  background-image: url(${props => props.bgXsmall});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 30% 0 10%;
  
  @media screen and (min-width: 480px) {
    padding: 25% 0 10%;
  }
  @media screen and (min-width: 576px) {
    background-image: url(${props => props.bgSmall});
  }
  @media screen and (min-width: 768px) {
    background-image: url(${props => props.bgLarge});
    padding: 14% 0 3%;
  }
  @media screen and (min-width: 1920px) {
    background-image: url(${props => props.bgXlarge});
    padding: 10% 0 2%;
  }
`

export default Header;