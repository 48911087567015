import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import CtaTjanster from "../components/cta-tjanster";
import ArticleFront from "../components/article-front";
import Projekt from "../components/projekt";
import CtaBanner from "../components/cta-banner";
import Map from "../components/map";
import SEO from "../components/seo";

const Index = ({data}) => (
  <Layout>
    <SEO
      title={data.markdownRemark.frontmatter.seo.title}
      description={data.markdownRemark.frontmatter.seo.description}
      keywords={data.markdownRemark.frontmatter.seo.keywords.toString().split(',')}
    />
    <CtaTjanster />
    <ArticleFront content={data.markdownRemark.html} />
    <CtaBanner />
    <Projekt />
    <Map />
  </Layout>
)

export const query = graphql`
{
  markdownRemark(frontmatter: {name: {eq: "Startsida"}}) {
    frontmatter {
      seo {
        description
        keywords
        title
      }
    }
    html
  }
}
`

export default Index