import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ProjectSliderItem = ({ post, placeholder }) => (
  <div className="md:bg-white">
    <Link
      key={post.frontmatter.title}
      to={post.frontmatter.path}
      className="no-underline text-grey-darker"
      style={{transition: 'color .15s'}}
    >
      <section className="flex flex-wrap flex-col md:flex-no-wrap md:items-center md:flex-row">
        <div className="w-full mb-6 md:w-1/2 md:mb-0">
          {thumbnailCheck(post.frontmatter.thumbnail, placeholder)}
        </div>
        <div className="w-full py-4 text-center md:text-left md:w-1/2 md:pl-8 md:pr-4">
          <h3 className="mb-4 text-grey-darker text-lg">{post.frontmatter.title}</h3>
          <p className="mb-2 text-grey-darker md:text-xs">{post.frontmatter.project_excerpt}</p>
          <span className="link text-orange md:text-xxs">Läs mer</span>
        </div>
      </section>
    </Link>
  </div>
)

function thumbnailCheck(thumbnail, placeholder) {
  if(thumbnail) {
    return (
      <Img fluid={thumbnail.childImageSharp.fluid} />
      )
    } else {
      return (
       <Img fluid={placeholder.childImageSharp.fluid} />
    )
  }
}

export default ProjectSliderItem