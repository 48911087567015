import React from "react";
import styled from "styled-components";
import map from "../images/map.jpg";

const Map = () => (
  <section className="bg-grey-lightest border-t-8 border-grey-lighter">
    <MapContainer className="p-8 md:py-13per">
      <div className="w-auto lg:w-4/5 xl:w-2/3 xxl:w-1/2 bg-white shadow-lg mx-auto p-4 flex-wrap flex justify-between sm:flex-no-wrap md:p-8">
        <a className="flex items-center w-full py-4  md:py-0 md:w-auto text-grey-darker no-underline text-center flex justify-center items-center hover:text-black" href="tel:+46 550 817 50">
        <svg
            className="fill-current mr-2"
            width="24"
            height="24"
            viewBox="0 0 48 30"
            xmlns="http://www.w3.org/2000/svg"
          >
          <path d="M38.981 27.622c.083.568-.11 1.063-.578 1.483l-5.49 4.894a3.099 3.099 0 0 1-.97.63c-.4.173-.792.284-1.177.334-.028 0-.11.006-.248.018a6.257 6.257 0 0 1-.537.019c-.523 0-1.37-.08-2.54-.241-1.169-.16-2.6-.556-4.293-1.186-1.693-.63-3.613-1.576-5.76-2.837-2.146-1.26-4.43-2.99-6.853-5.19-1.927-1.706-3.523-3.337-4.79-4.894-1.265-1.558-2.284-2.998-3.054-4.32-.771-1.322-1.35-2.521-1.735-3.596C.571 11.66.31 10.734.172 9.955.034 9.176-.021 8.565.007 8.12c.027-.445.041-.692.041-.742.055-.346.18-.698.372-1.057.192-.358.426-.648.702-.87L6.612.518C6.999.173 7.439 0 7.935 0c.358 0 .674.093.95.278.275.185.509.414.701.686l4.418 7.526c.248.396.317.828.206 1.298-.11.47-.344.865-.701 1.187l-2.023 1.816a.603.603 0 0 0-.145.241.833.833 0 0 0-.062.278c.11.52.358 1.113.743 1.78.33.593.84 1.316 1.528 2.169.688.853 1.665 1.835 2.931 2.947 1.239 1.138 2.34 2.021 3.303 2.652.963.63 1.769 1.093 2.415 1.39.647.296 1.143.476 1.487.537l.516.093c.055 0 .144-.019.268-.056a.715.715 0 0 0 .269-.13l2.353-2.15a2.699 2.699 0 0 1 1.734-.593c.468 0 .84.074 1.115.222h.04l7.969 4.227c.578.322.922.73 1.032 1.224z" fill="#FC9F00"/>
        </svg>

        <span>0550-817 50</span>
        </a>

        <a className="flex items-center w-full py-4  md:py-0 md:w-auto text-grey-darker no-underline text-center flex justify-center items-center hover:text-black" href="mailto:info@brosvets.se">
        <svg
            className="fill-current mr-2"
            width="24"
            height="24"
            viewBox="0 0 48 30"
            xmlns="http://www.w3.org/2000/svg"
          >
          <path d="M22.034 18h.01c.332 0 .661-.098.927-.277l.073-.047.048-.031.841-.708L44 .098A.235.235 0 0 0 43.812 0H.144A.224.224 0 0 0 0 .053L20.995 17.65c.264.223.642.35 1.04.35zM0 2v25l15-12.374L0 2zm29 12.666L44 27V2L29 14.666zm-4.74 4.257a3.394 3.394 0 0 1-2.198.786 3.397 3.397 0 0 1-2.2-.782L16.428 16 0 29.715v.044c0 .13.108.241.236.241h43.532c.125 0 .232-.11.232-.242v-.04L27.586 16.086l-3.326 2.837z" fill="#FC9F00"/>
        </svg>
        <span>info@brosvets.se</span>
        </a>

        <a className="flex items-center w-full py-4  md:py-0 md:w-auto text-grey-darker no-underline text-center flex justify-center items-center hover:text-black" href="tel:+46 550 817 50">
        <svg
            className="fill-current mr-2"
            width="24"
            height="24"
            viewBox="0 0 48 30"
            xmlns="http://www.w3.org/2000/svg"
          >
          <path d="M14.501 41l11.601-16.59c4.351-5.744 3.722-15.185-1.347-20.203A14.477 14.477 0 0 0 14.501 0 14.48 14.48 0 0 0 4.248 4.206c-5.07 5.018-5.698 14.46-1.365 20.18L14.501 41zm.134-32.015c2.92 0 5.294 2.352 5.294 5.241 0 2.89-2.375 5.242-5.294 5.242-2.919 0-5.293-2.352-5.293-5.242 0-2.889 2.374-5.24 5.293-5.24z" fill="#FC9F00"/>
        </svg>
        <span>Lagmansgatan 1,{" "}<br className="md:hidden"/>Kristinehamn</span>
        </a>
      </div>
    </MapContainer>
  </section>
)

const MapContainer = styled.div`
background-image: url(${map});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
`

export default Map;