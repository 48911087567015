import { StaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Slider from "react-slick";
import ProjectSliderItem from "../components/project-slider-item";

const Projekt = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholder: file(relativePath: { eq: "placeholder-640x500.jpg" }) {
          childImageSharp {
            fluid {
              src
              sizes
              srcSet
              aspectRatio
            }
          }
        }
        allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {type: {eq: "projekt"}}}) {
          edges {
            node {
              id
              excerpt(pruneLength: 128)
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                project_excerpt
                thumbnail {
                  childImageSharp {
                    fluid {
                      src
                      sizes
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
              html
            }
          }
        }
      }
    `}
    render={data => (
      <section className="bg-grey-lightest">
        <div className="container p-8 md:py-8 sm:w-2/3 xl:w-1/2 mx-auto">
          <h2 className="text-center mb-6 lg:mb-8">Våra utförda projekt</h2>
          <Slider {...sliderSetting()}>
            {data.allMarkdownRemark.edges
              .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
              .map(edge => <ProjectSliderItem key={edge.node.id} post={edge.node} placeholder={data.placeholder} />)}
          </Slider>

          <div className="text-center mt-12">
          <Link
            to="/vara-projekt"
            className="button link gray hover:bg-grey-lighter transition"
          >
            Visa alla projekt
          </Link>
          </div>
        </div>
      </section>
    )}
  />
)

function sliderSetting() {
  return {
      arrows: true,
      autoplay: true,
      autoplaySped: 4000,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
}

export default Projekt;