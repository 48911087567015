import { StaticQuery, graphql } from "gatsby";
import React from "react";
import Img from "gatsby-image"

const ArticleFront = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "article-front-1024x640.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        markdownRemark(frontmatter: {name: {eq: "Startsida"}}) {
          frontmatter {
            article_front {
              heading
              html
            }
          }
        }
      }
    `}
    render={data => (
      <div className="relative">
        <section className="bg-white sm:py-8 relative z-10 md:py-16">
          <article className="article container mx-auto flex flex-column flex-col-reverse flex-wrap justify-between sm:flex-row sm:items-center md:px-8 xl:px-16">
            <div role="main" className="w-full px-4 py-8 md:pb-0 sm:pt-0 flex items-center text-center sm:text-left sm:w-1/2 md:px-0 md:mb-0">
              <div>
                <h2 className="mb-6 md:md-12">{data.markdownRemark.frontmatter.article_front.heading}</h2>
                <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.frontmatter.article_front.html }} />
              </div>
            </div>

            <aside className="w-full sm:px-4 sm:w-1/2 md:px-0 lg:w-2/5 relative">
              <Img fluid={data.fileName.childImageSharp.fluid} />
            </aside>
          </article>
        </section>
      </div>
    )}
  />
)

export default ArticleFront;